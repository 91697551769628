/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import "node_modules/bootstrap/scss/functions";

@import "node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Whatsapp variables
@import "theme/whatsapp";

// Theme functions
@import "theme/functions";

// Custom variables
@import "variables/user-variables";

// Custom theme color
@import "colors/blue";

// Theme variables
@import "theme/variables";

// Bootstrap core
@import "node_modules/bootstrap/scss/bootstrap";

// Theme core
@import "theme/theme";

// Custom core
@import "custom";
